$checkout-blue-btn: #1f81ff;
$checkout-disabled-btn: #8daaf3da;
$input-error: red;
$grey: #aaaaaa;
$medium-grey: #777777;
$receipt-green: #adf69e;
$discount-value: #47de29;
$input-background: #f1f1f1;
$text-color: #2a2927;
$summary-yellow: #ffff66;
$next-steps-blue: #baecf5;

$success: #cce6da;
$success-text: #155724;
$error: #f7afc4;
$error-text: #721c24;
$warning: #ffad0d;
$dark-text: #262422;
