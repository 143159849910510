.StripeElement {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  font-weight: 400;
  color: black;
  font-family: sans-serif;
}

.StripeElement:focus,
.StripeElement--focus {
  outline: none;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
