@import '../../styling/palette.scss';
@import './wizard.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.terms-desktop {
  font-size: 13px;
  margin-top: 30px;
}

.header {
  align-items: center;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;

  button {
    margin-right: auto;
    transform: translateX(30px);

    .back-arrow {
      font-size: 18px;
      font-weight: 100;
    }
  }

  .image-logo {
    margin: 25px 0;

    &.centralized {
      margin-right: auto;
    }
  }
}

.hero {
  margin: 65px 0;
  text-align: center;

  .icon {
    height: 35px;
  }

  h2 {
    color: #262422;
    font-family: 'Flama Condensed', sans-serif;
    font-size: 35px;
    margin-top: 15px;
    text-transform: uppercase;
  }
}

.loading {
  display: flex;
  justify-content: center;
}

.copy {
  color: $dark-text;
  font-size: 16px;

  a {
    color: #4c80ff;
  }
}

.receipt-copy {
  text-align: center;
}

.setup-loading {
  padding: 100px 0;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;

  .error {
    font-size: 12px;
    margin: 0;
    margin-top: 4px;
    color: $input-error;
    width: 100%;
  }

  &.purchase-form {
    .flex-column {
      display: flex;
      flex-direction: column;
    }

    label {
      margin-top: 1rem;
    }

    .credit-card-field {
      margin-top: 30px;
      width: 100%;
      position: relative;

      &:hover {
        input,
        .StripeElement {
          border-color: rgba(0, 0, 0, 0.87);
          transition: all 0.2s;
        }
      }

      &:focus-within {
        label {
          color: #1f81ff;
          transition: all 0.2s;
        }

        input,
        .StripeElement {
          border-color: #1f81ff;
          box-shadow: inset 0px 0px 0px 1px #1f81ff;
          transition: all 0.2s;
        }
      }

      label {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.54);
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-size: 12px;
        font-weight: 400;
        left: 9px;
        letter-spacing: 0.00938em;
        line-height: 1;
        margin-top: 0;
        padding: 0 5px;
        position: absolute;
        top: -6px;

        &.focused {
          color: #1f81ff;
          transition: all 0.2s;
        }
      }

      input,
      .StripeElement {
        cursor: text;
        box-sizing: border-box;

        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        width: 100%;
      }

      .StripeElement.focused {
        border-color: #1f81ff;
        box-shadow: inset 0px 0px 0px 1px #1f81ff;
        transition: all 0.2s;
      }
    }

    .coupon-container {
      display: flex;
      position: relative;

      button {
        align-self: flex-end;
        border-radius: 0 4px 4px 0;
        height: 52px;
        border-left: none;
        margin-left: -70px;
        width: 70px;
      }

      .MuiButton-label {
        color: black;
        font-size: 15px;
        position: relative;
        text-transform: capitalize;

        &:after {
          border-color: black;
          border-style: solid;
          border-width: 0 0 1px;
          bottom: 1px;
          content: '';
          left: 10%;
          position: absolute;
          width: 80%;
        }
      }
    }

    .alert {
      margin: 0;
      margin-top: 8px;

      &.success {
        color: $grey;
      }
    }

    button:disabled {
      cursor: not-allowed;
      background-color: $checkout-disabled-btn;
    }

    .loading {
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        margin-top: 50px;
        text-align: center;
      }
    }
  }

  .radio-input {
    margin: 20px 0 0;
    display: flex;
    flex-direction: column;

    .MuiFormControlLabel-root:first-child {
      margin-top: 0;
    }

    p {
      font-family: 'Flama Condensed';
      font-weight: bold;
      font-size: 14px;
      margin: 0;
      text-transform: uppercase;
      width: 100%;
      margin-bottom: 5px;
    }

    .radio-group {
      display: flex;
      justify-content: flex-start;

      span {
        font-family: 'Akkurat';
      }
    }
  }

  .same-line {
    display: flex;
  }

  .same-column {
    display: flex;
    flex-direction: column;
  }

  .right-margin {
    margin-right: 1rem;
  }

  button[type='submit'] {
    cursor: pointer;
    padding: 0;
    font-size: 18px;
    border: 0;
    min-width: 140px;
    height: 60px;
    line-height: 22px;
    color: white;
    background: $checkout-blue-btn;
    border-radius: 0;
    margin: 1.5rem 0;

    &:hover {
      background: $checkout-blue-btn;
    }
  }
}

.wizard {
  form button[type='submit'] {
    align-self: flex-end;
    font-size: 12px;
    font-weight: bold;
    height: auto;
    line-height: 40px;
    padding: 0 20px;

    .arrow-right {
      display: block;
      height: 10px;
      width: 10px;
      margin-left: 5px;
      background: url('/assets/images/arrow-right-icon.svg') no-repeat center center;
    }
  }

  .owner,
  .billing,
  .checkout,
  .shipping,
  .pet,
  .vet {
    height: 0;
    visibility: hidden;
  }

  &.prev-checkout {
    .owner,
    .billing,
    .checkout {
      height: auto;
      visibility: visible;
    }

    .shipping,
    .pet,
    .vet {
      margin-bottom: 0;
    }
  }

  &.post-checkout {
    .shipping,
    .pet,
    .vet {
      height: auto;
      visibility: visible;
    }

    .owner,
    .billing,
    .checkout {
      margin-bottom: 0;
    }
  }
}

.title-container {
  background-color: $summary-yellow;
  margin: 20px 0 30px;

  .title {
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 17px 0 17px 30px;
    font-weight: bold;
  }
}

.summary-container {
  display: none;
}

.plan {
  margin-top: 30px !important;
}

.checkout-wrap {
  display: flex;
  justify-content: center;
  max-width: 1100px;
  padding: 0 20px;
  width: 90%;

  .content {
    width: 100%;
  }
}

.content {
  width: 300px;
}

.lifetime-only {
  font-size: 12px;
  position: relative;
  left: 33px;
  top: -5px;
}

@media only screen and (min-width: 900px) {
  .has-summary {
    justify-content: space-between;

    .content {
      width: 535px;
    }

    .summary-container {
      display: block;
      width: 325px;
    }
  }
}
