@import '../../styling/palette.scss';

.setup-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;

  .divider {
    background-color: $grey;
    height: 1px;
    position: absolute;
    width: 90%;
    z-index: -1;
    margin-top: -9px;
  }

  .step-container {
    width: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .step {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: $grey;
      font-weight: bold;
      border-radius: 50%;
      width: 34px;
      height: 34px;
      font-size: 14px;
      border: 2px solid $grey;
      cursor: pointer;
      outline: none;
    }

    .step:disabled {
      cursor: not-allowed;
    }

    .current {
      border-color: #1f81ff;
      background-color: white;
      color: #262422;
      width: 42px;
      height: 42px;
    }

    .active-label {
      color: black;
    }

    .validated {
      background-color: $receipt-green;
      border-color: $receipt-green;
      color: white;
    }

    p {
      margin: 0;
      margin-top: 6px;
      font-size: 9px;
      color: $grey;
      width: unset;
    }
  }
}

@media only screen and (min-width: 400px) {
  .setup-steps {
    width: 300px;

    .divider {
      width: 300px;
    }
  }
}
