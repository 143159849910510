@import './palette.scss';

/* Regular */
@font-face {
  font-family: Akkurat;
  src: url('/assets/fonts/Akkurat.ttf');
  font-style: normal;
  font-weight: normal;
}

/* Regular */
@font-face {
  font-family: 'Flama Condensed';
  src: url('/assets/fonts/FlamaCondensed-Basic.woff');
  font-style: normal;
  font-weight: normal;
}

/* Medium */
@font-face {
  font-family: 'Flama Condensed';
  src: url('/assets/fonts/FlamaCondensed-Medium.woff');
  font-style: normal;
  font-weight: bold;
}

html,
body,
#root,
#root > div,
main {
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: Akkurat, sans-serif;
  font-weight: normal;
  color: $text-color;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  ::-ms-input-placeholder {
    @content;
  }
}

@include placeholder {
  color: $grey !important;
  font-size: 16px;
  font-family: Akkurat;
}

.no-style-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.image-logo {
  width: 100px;
  height: 23px;
  background: url(/assets/images/gallant-logo.svg) round;
}
