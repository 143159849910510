@import '../../../styling/palette.scss';

.date-picker-container {
  #mui-pickers-date-helper-text {
    font-family: 'Akkurat';
    font-size: 12px;
    color: $input-error;
    width: 100%;
  }
}
