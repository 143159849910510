@import '../../../styling/palette.scss';

.input {
  width: 100%;

  .react-tel-input {
    input[type='tel'] {
      font-family: Akkurat;
      line-height: 17px;
      font-size: 16px;
      padding: 16px;
      border: 0;
      width: 100%;
      box-sizing: border-box;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
    }
  }

  .label {
    display: flex;
    align-items: flex-end;
    font-weight: bold;
    font-family: 'Flama Condensed';
    margin: 0;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;

    .optional {
      font-family: 'Akkurat';
      font-weight: normal;
      margin: 0;
      margin-left: 6px;
      font-weight: normal;
      text-transform: capitalize;
      font-size: 10px;
      width: 40px;
    }
  }

  .sub-label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    margin: 8px 12px 0;
  }

  .error {
    font-size: 12px;
    margin-top: 4px 0 0;
    color: $input-error;
    width: 100%;
  }
}

.phone-input {
  position: relative;

  &:hover input[type='tel'] {
    border-color: rgba(0, 0, 0, 0.87);
    transition: all 0.2s;
  }

  &:focus-within {
    label {
      color: #1f81ff;
      transition: all 0.2s;
    }

    .react-tel-input input[type='tel'] {
      border-color: #1f81ff;
      box-shadow: inset 0px 0px 0px 1px #1f81ff;
      transition: all 0.2s;
    }
  }

  label {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.54);
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 12px;
    font-weight: 400;
    left: 9px;
    letter-spacing: 0.00938em;
    line-height: 1;
    margin-top: 0;
    padding: 0 5px;
    position: absolute;
    top: -6px;
    z-index: 1;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
