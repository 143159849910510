@import '../../../styling/palette.scss';

#toast-container {
  position: fixed;
  z-index: 1000;

  * {
    box-sizing: border-box;
  }

  > div {
    position: relative;
    overflow: hidden;
    margin: 0 0 0.4em;
    padding: 1em 1em 1em 3em;
    border-radius: 0.2em 0.2em 0.2em 0.2em;
    box-shadow: 0 0 0.75em rgba(10, 31, 68, 0.24);
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80);
  }

  > div:hover {
    box-shadow: 0 0 0.75em #211e1f;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    cursor: pointer;
  }

  .toast:before {
    position: fixed;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: bold;
    margin: 0 0 0 -1.8em;
  }

  .toast-error:before {
    content: '\f057';
  }

  .toast-success:before {
    content: '\f058';
  }

  .toast-warning:before {
    content: '\f071';
  }
}

.toast {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.toast-close-button {
  order: 2;
  font-size: 1.3em;
  border: 0;
  background: transparent;
  color: white;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: rgba(10, 31, 68, 0.24);
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

.toast-message {
  order: 1;
  font-size: 0.9em;
}

.toast-success {
  background-color: $success;
  color: $success-text;

  .toast-close-button {
    color: $success-text;
  }
}

.toast-error {
  background-color: $error;
  color: $error-text;

  .toast-close-button {
    color: $error-text;
  }
}

.toast-warning {
  background-color: $warning;
  color: $dark-text;

  .toast-close-button {
    color: $dark-text;
  }
}

/* Place toast */

#toast-container.toast-top-full-width > div {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

@media only screen and (max-width: 812px) {
  #toast-container.toast-top-full-width > div {
    width: 96%;
    margin-top: 1rem;
  }
}
