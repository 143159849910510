.wizard {
  counter-reset: item;
  list-style: none;
  padding: 0;

  &-item {
    counter-increment: item;
    cursor: not-allowed;
    margin-bottom: 40px;
    position: relative;
  }

  &-item:before {
    background: #f5f5f5;
    border-radius: 100%;
    color: #939291;
    content: counter(item);
    font-weight: bold;
    height: 36px;
    left: 0;
    line-height: 38px;
    position: absolute;
    text-align: center;
    top: -8px;
    transition: background 0.4s ease-out;
    width: 36px;
  }

  &-item-heading {
    font-size: 16px;
    font-weight: bold;
    color: #939291;
    margin-bottom: 10px;
    padding-left: 45px;
  }

  &-item-content {
    height: 0;
    visibility: hidden;
    margin-top: 20px;
  }

  &-item.active {
    cursor: auto;

    &:before {
      background-color: #fff;
      border: 2px solid #1f81ff;
      color: #262422;
      line-height: 34px;
      height: 32px;
      width: 32px;
    }

    .wizard-item-heading {
      color: #262422;
      transition: color 1s ease-out;
    }

    .wizard-item-content {
      height: auto;
      visibility: visible;
    }
  }

  &-item.completed {
    cursor: pointer;

    &:before {
      background-color: #83f79b;
      line-height: 39px;
      color: #fff;
      content: url('/assets/images/completed-icon.svg');
    }
  }

  @media screen and (min-width: 768px) {
    &-item {
      margin-bottom: 50px;
    }

    &-item:before {
      height: 42px;
      left: -55px;
      line-height: 42px;
      top: -13px;
      width: 42px;
    }

    &-item-heading {
      padding-left: 0;
    }

    &-item-content {
      margin-top: 0;
    }

    &-item.active {
      &:before {
        height: 40px;
        line-height: 40px;
        width: 40px;
      }

      .wizard-item-content {
        margin-top: 30px;
      }
    }

    &-item.completed:before {
      height: 34px;
      left: -49px;
      line-height: 38px;
      margin-bottom: 50px;
      top: -8px;
      width: 34px;
    }
  }
}
