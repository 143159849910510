@import '../../styling/palette.scss';

@keyframes flashing {
  from {
    box-shadow: 0px 0 2px $checkout-blue-btn;
  }
  to {
    box-shadow: 0px 0 6px $checkout-blue-btn;
  }
}

.timeline {
  &-item {
    border-bottom: 1px solid $input-background;
    padding: 20px 0 20px 20px;
    position: relative;

    &:before {
      background-color: $input-background;
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 1px;
    }

    &:after {
      background-color: $grey;
      border-radius: 100%;
      border: 3px solid white;
      box-shadow: 0px 0 4px $grey;
      content: '';
      height: 5px;
      left: -5px;
      position: absolute;
      top: 26px;
      width: 5px;
    }
  }

  &-item:first-child {
    padding-top: 0;

    &:before {
      height: calc(100% - 10px);
    }

    &:after {
      top: 7px;
    }
  }

  &-item:last-child {
    &:before {
      top: 0;
      height: 30px;
    }

    &:after {
      animation-direction: alternate;
      animation-duration: 0.8s;
      animation-iteration-count: infinite;
      animation-name: flashing;
      background-color: $checkout-blue-btn;
      box-shadow: 0px 0 4px $checkout-blue-btn;
    }
  }

  &-item:last-child {
    border-bottom: none;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-action {
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5em;
    margin: 0;
    max-width: 220px;
  }

  &-time {
    color: $grey;
    display: inline-block;
    font-size: 14px;
  }

  &-highlight {
    background-color: $input-background;
    border-radius: 5px;
    color: #000;
    margin-top: 15px;
    padding: 10px 20px;
    text-transform: uppercase;
  }
}
