@import '../../styling/palette.scss';

.summary {
  background-color: #fafafa;
  width: 100%;

  &-title {
    background-color: $summary-yellow;
    color: $dark-text;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 20px 25px;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 35px;

    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      p {
        font-size: 15px;
        margin: 0;
        text-transform: capitalize;
      }

      span {
        color: #aaa;
        font-size: 12px;
        text-transform: lowercase;
      }

      #discount-value {
        color: $discount-value;
      }

      .subscription-selector {
        align-self: flex-end;

        #select-subscription-type {
          padding: 0;
          padding-right: 1.5rem;
        }

        div > svg {
          margin-right: -5px;
          margin-bottom: 1px;
        }
      }

      .subscription-type {
        text-transform: capitalize;
      }
    }

    li.highlight {
      border-top: 1px solid #d4d4d4;
      padding-top: 30px;
      margin-bottom: 0;

      p {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
        color: $dark-text;
      }
    }
  }

  .terms {
    font-size: 13px;
    margin: 0;
    padding: 20px;
    display: none;
  }
}
