.pet-box {
  background-color: #fbfbfb;
  border-radius: 5px;
  border: 1px solid #cccccc;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 15px;
  padding: 16px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 15px;

  &.no-procedure-date-container {
    border-color: red;
  }

  .plan-contanier {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }

  .no-procedure-date {
    font-size: 12px;
    color: red;
    text-align: right;
  }

  p {
    font-size: 14px;
    text-transform: capitalize;
    padding: 0;
    margin: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .name {
    margin: 0 0 5px;
    font-size: 15px;
  }

  .picture-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebebeb;
    border-radius: 5px;
  }

  .picture-wrapper .picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .picture-wrapper .picture-icon {
    max-width: 32px;
    object-fit: unset;
  }
}

.profile .success-container .success-summary {
  .copy {
    margin: 20px 0px;
  }

  .summary {
    display: block;
  }

  .title-container {
    margin-top: 0;
  }

  .title {
    box-sizing: border-box;
    margin-top: 0;
    padding: 8px 20px;
  }

  .title a {
    align-items: center;
    align-self: flex-end;
    color: #000;
    display: flex;
    float: right;
    text-decoration: none;
    cursor: pointer;
  }

  .icon {
    background-size: 16px;
    display: inline-block;
    height: 16px;
    margin-right: 5px;
    width: 16px;
  }

  .icon.edit {
    background-image: url(/assets/images/edit.svg);
  }

  .icon.add {
    background-image: url(/assets/images/add.svg);
  }

  .icon.save {
    background-image: url(/assets/images/save.svg);
  }

  .pet-photo-container {
    width: 100%;
    height: 200px;
    background: #f5f5f5;
    border-radius: 5px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pet-photo-uploader {
    display: none;
  }

  .pet-photo-uploader-label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .pet-photo-container .picture-icon {
    width: 100px;
    height: 90px;
  }

  .pet-photo-container .picture {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
    cursor: pointer;
  }
}

.profile form {
  .actions {
    margin: 20px 0;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  .actions button {
    color: #000;
    margin: 0;
    height: 40px;
    border-radius: 5px;
    border: 1px solid transparent;
    font-size: 12px;
    letter-spacing: 1px;
    width: 45%;

    &.cancel {
      background-color: #fff;
      border-color: #ccc;
    }

    &.save {
      background-color: #0492ff;
      color: #fff;
    }
  }
}
