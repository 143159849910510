.plan {
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 20px;
  position: relative;

  .badge {
    margin-top: 15px;
    text-align: center;

    img {
      width: 40px;
    }
  }

  &-heading {
    background-color: #fff;
    color: #1f81ff;
    display: inline-block;
    font-size: 13px;
    margin: 0;
    padding: 0 10px;
    text-transform: uppercase;
    transform: translate3d(-10px, -31px, 0);
  }

  &-description {
    color: #262422;
    font-size: 14px;
    margin: -15px 0 0 0;
    text-transform: capitalize;

    .plan-price {
      font-weight: bold;
      margin-left: 10px;

      .plan-price-caption {
        font-weight: normal;
        text-transform: lowercase;
        font-size: 12px;
      }
    }
  }

  &-items {
    list-style-type: none;
    margin-top: 13px;
    padding: 0;

    li {
      color: #7b7b7b;
      font-size: 14px;
      margin-bottom: 10px;
      margin-left: 5px;
      padding-left: 5px;
      position: relative;
    }

    li:before {
      background-color: #7b7b7b;
      border-radius: 3px;
      content: '';
      height: 4px;
      left: -5px;
      position: absolute;
      top: 8px;
      width: 4px;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 360px) {
    .badge {
      margin-top: 0;
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  @media (min-width: 900px) {
    .badge {
      right: 20px;
      top: 20px;

      img {
        width: 100%;
      }
    }
  }
}
