@import '../../styling/palette.scss';

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .success-cta {
    height: 40px;
    background: #0093ff;
    border-radius: 4px;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    margin: 8px 0;
  }

  .success-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    .success-check-circle {
      margin-top: 50px;
      width: 150px;
      height: 150px;
      background: url(/assets/images/success-circle.svg) round;
    }

    .success-copy {
      margin: 50px 0;
    }
  }

  .succes-next-pet {
    flex: 0 1 auto;
    cursor: pointer;
    padding: 0;
    width: 100%;
    height: 60px;
    font-size: 18px;
    border: 0;
    line-height: 22px;
    background: $checkout-blue-btn;
    border-radius: 0;
    margin-top: 61px;

    a {
      text-decoration: none;
      color: white;
    }

    &:hover {
      background: $checkout-blue-btn;
    }
  }

  .success-summary {
    width: 100%;

    ul {
      display: flex;
      flex-direction: column;
      padding: 0;

      &.fluid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -10px;

        li {
          min-width: 140px;
          padding: 0 10px;
        }
      }

      .same-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      li {
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
        }
      }

      p:first-child {
        color: $grey;
        margin-bottom: 10px;
      }

      p:last-child {
        text-align: left;
        margin-bottom: 20px;
      }
    }
  }

  .success-copy {
    margin: 0 37px;
  }

  .support-copy {
    color: $grey;
    font-size: 13px;
    margin: 0;

    a {
      color: $grey;
    }
  }

  .support-copy:last-child {
    margin-bottom: 40px;
  }

  hr {
    border-top: none;
    margin: 25px 0;
  }

  h2 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .hear-about-us {
    label {
      font-size: 17px;

      > span {
        padding-bottom: 0;
        padding-top: 5px;
      }
    }

    .MuiTextField-root {
      margin-top: 10px;
    }

    .MuiIconButton-colorPrimary:hover {
      background-color: white;
    }

    .MuiTouchRipple-root {
      visibility: hidden;
    }

    button {
      margin-bottom: 0;
      transform: scale(0.8);
    }
  }
}
